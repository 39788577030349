<template>
  <div class="pb-5 mb-2">
    <div class="d-flex align-items-center mb-4">
      <div class="col-md-6">
        <a @click="$router.back()" class="text-dark d-flex align-items-center cursor-pointer">
          <span class="d-flex align-items-center">
            <vs-icon icon="chevron_left"></vs-icon>
          </span>
          <span class="text-underline">Back</span>
        </a>
      </div>
      <div class="col-md-6">
        <div class="text-right">
          <button @click="deleteRelease(releaseData)" class="btn btn-outline-danger px-3">
            Delete
          </button>

          <button class="btn btn-primary btn-site px-3" @click="updateRelease('approved')">
            Approve
          </button>

          <button @click="reasonModal = true" class="btn btn-primary btn-site-outline px-3">
            Decline
          </button>
        </div>
      </div>
    </div>
    <SectionLoading v-if="loading" text="Release loading..." />
    <div v-else>
      <div class="row">
        <div class="col-md-3">
          <div class="download-art">
            <img class="img-fluid albumImg w-full" :src="releaseData.cover_image_path || ''" :alt="releaseData.title" />
            <a :download="releaseData.title" :href="releaseData.cover_image_path" :title="releaseData.title"
              target="_blank">
              <vs-icon icon="download"></vs-icon>
            </a>
          </div>
        </div>
        <div class="col-md-9">
          <div class="releaseData">
            <p class="type">{{ releaseData.type }}</p>
            <h1 class="title mb-3">{{ releaseData.title }}</h1>
            <p class="mb-1" v-if="releaseData.user">
              <router-link :to="`/artists/${releaseData.user.uuid}`">{{ releaseData.user.artist_name }}
              </router-link><span class="text-dark">● {{ releaseData.label }} </span>
            </p>
            <p class="releaseinf">
              <span class="titl">DATE ADDED: </span><span class="text-dark">{{
                moment
                  .utc(new Date(releaseData.created_at))
                  .format("DD/MM/YYYY")
              }}</span>
            </p>
            <p class="releaseinf">
              <span class="titl">EXPECTED RELEASE DATE: </span>
              <span class="text-dark">
                {{  getReleaseDate(releaseData.release_date) }}
              </span>
            </p>
            <p class="releaseinf">
              <span class="titl">STATUS: </span><span class="text-dark">{{
                releaseData.status | capitalize
              }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="card siteCard mt-3">
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Title</th>
                <th scope="col">Artist</th>
                <th scope="col">Writers</th>
                <th scope="col">Creators</th>
                <th scope="col">Recording Year</th>
                <th scope="col">Stores</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(release, index) in releaseData.tracks" :key="index">
                <td>
                  <p class="text-dark mb-0">{{ index + 1 }}</p>
                </td>

                <td>
                  <div class="playBtn" @click="playAudio(release.audio_path, release.id)">
                    <img v-if="$store.getters.audioPlay.status &&
                      $store.getters.audioPlay.id == release.id
                      " src="/images/play.svg" height="20" alt="" />
                    <vs-icon v-else icon="play_circle_filled "></vs-icon>
                    <div class="pl-2">
                      <span class="text-dark mb-0 title">{{
                        release.title
                      }}</span>
                      <div>
                        <span class="explicit" v-if="release.explicit_content == 'Yes'"
                          style="font-size: 12px">Explicit</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="creatorList">
                    <span>
                      {{ release.artist_name }}
                    </span>
                  </p>
                </td>

                <td>
                  <p class="text-dark mb-0 creatorList">
                    <span v-for="(writer, index) in release.writers" :key="index">{{ writer.name }}
                      <span v-if="index + 1 < release.writers.length">, </span>
                    </span>
                  </p>
                </td>

                <td>
                  <!-- <p class="creatorTitle">Primary Artist</p> -->
                  <p class="text-dark mb-0 creatorList">
                    <span v-for="(artist, index) in release.artists" :key="index">{{ artist.name }} <span>({{ artist.role
                    }})</span>
                      <span v-if="index + 1 < release.artists.length">, </span>
                    </span>
                  </p>
                </td>

                <td>
                  <p class="text-dark mb-0">
                    {{ release.year }}
                  </p>
                </td>
                <td>
                  <p class="text-dark mb-0 creatorList">
                    <span v-for="(store, index) in release.stores" :key="index">{{ store.name }}
                      <span v-if="index + 1 < release.stores.length">, </span>
                    </span>
                  </p>
                </td>
                <td>
                  <vs-button @click="downloadAudioFile(release.audio_path, release.id)" color="primary" type="flat"
                    icon="file_download" size="small">Download</vs-button>

                  <vs-button class="text-left" v-if="release.artists.length > 0" @click="viewSplitListing(release.artists)" color="primary"
                    type="flat" icon="percent" size="small">View Contributors/Split</vs-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card siteCard mt-3">
        <div class="p-3">
          <h5 class="text-dark mb-4">Release Data</h5>
          <div class="row">
            <div class="col-md-3">
              <div class="metaDisplay">
                <p class="headi">Title</p>
                <p class="data">
                  {{ releaseData.title }}
                </p>
              </div>
            </div>

            <div class="col-md-3">
              <div class="metaDisplay">
                <p class="headi">Label</p>
                <p class="data">{{ releaseData.label }}</p>
              </div>
            </div>

            <div class="col-md-3">
              <div class="metaDisplay">
                <p class="headi">Genre</p>
                <p class="data">{{ releaseData.genre }}</p>
              </div>
            </div>

            <div class="col-md-3">
              <div class="metaDisplay">
                <p class="headi">Language</p>
                <p class="data">{{ releaseData.language }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input style="opacity: 0; position: absolute; top: 0; left: 0" type="text" v-model="itemCopy" id="copy-text" />

    <vs-popup class="rateModal" :title="`Add reason to decline`" :active.sync="reasonModal">
      <form class="p-4" @submit.prevent="updateRelease('rejected')" role="form">
        <vs-select style="width: 100%" label="Select reason" class="mb-3" v-model="reasonSelected">
          <vs-select-item :key="index" :value="item.text" :text="item.text" v-for="(item, index) in optionsListing" />
        </vs-select>
        <vs-textarea v-if="reasonSelected == 'Others'" required label="Reason to decline" v-model="reason" />

        <div class="form-group text-center mt-5">
          <button type="submit" :disabled="btnLoading" class="btn btn-primary btn-block btn-site px-5">
            Send Reason
            <BtnLoading v-if="btnLoading" class="d-inline-block" height="18" />
          </button>
        </div>
      </form>
    </vs-popup>

    <vs-popup class="updateModal" title="Contributors / Split" :active.sync="splitView">
      <div>
        <div class="split-card" v-for="(split, index) in splitData" :key="index">
          <div class="split-item">
            <h2>{{ split.name }}</h2>
            <p>{{ split.email }}</p>
            <div>
              <span class="badge badge-primary text-white">
                {{ split.role }}
              </span>
            </div>
            <div v-if="split.split_percentage" class="mt-2">
              <span class="mb-1">Split Percentage: <span class="font-bold">
                  {{ split.split_percentage }}%
                </span>
              </span>

              <div class="split-timeline">
                <div class="base" :style="`width: ${split.split_percentage}%`">
                </div>
                <div class="base-back"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import SectionLoading from "../../../components/SectionLoading.vue";
import BtnLoading from "@/components/BtnLoading.vue";
import moment from "moment";


export default {
  name: "Release",
  components: {
    SectionLoading,
    BtnLoading,
  },
  data() {
    return {
      loading: false,
      releaseData: {},
      itemCopy: "",
      reasonModal: false,
      btnLoading: false,
      reasonSelected: "",
      splitView: false,
      splitData: {},
      optionsListing: [
        {
          text: "We flagged your release because we noticed an error within your metadata. Please review and redeliver",
        },
        {
          text: "Your release was flagged because on copyright grounds. Please reach out to support@melior.africa for more information",
        },
        { text: "Others" },
      ],
      reason: "",
      allTracks: [
        {
          id: 1,
          title: "Gasoline",
          artists: ["The Weekend", "Horpey"],
          producers: ["Young John"],
          writers: ["Jinmi Writes"],
          year: "2022",
          splits: [{ id: 1, name: "Yolly" }],
          source:
            "https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3",
        },
        {
          id: 2,
          title: "How do I make you love me?",
          artists: ["The Weekend", "Horpey"],
          producers: ["Young John"],
          writers: ["Jinmi Writes"],
          year: "2022",
          splits: [{ id: 1, name: "Yolly" }],
          source:
            "https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3",
        },
      ],
    };
  },
  mounted() {
    this.getReleaseByID();
  },
  methods: {
    getReleaseDate(date) {
      if ( date.length > 10) {
        return moment.utc(date).format("DD/MM/YYYY");
      }
      return date
    },
    deleteRelease(releaseData) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `Are you sure you want to delete this release ?`,
        accept: () => {
          this.$vs.loading();

          let fetch = {
            path: `admin/releases/delete/${this.$route.params.id}`,
            data: {},
          };
          this.$store
            .dispatch("deleteRequest", fetch)
            .then((resp) => {
              this.$toast.success(
                "Release Delete",
                "Release has been deleted successfully",
                this.$toastPosition
              );

              this.$vs.loading.close();
              setTimeout(() => {
                this.$router.push("/incoming-release");
              }, 2000);
            })
            .catch((err) => {
              this.$vs.loading.close();
              if (err.response) {
                this.$toast.info(
                  err.response.data.message,
                  "Unable to delete Release",
                  this.$toastPosition
                );
              } else {
                this.$toast.info(
                  "Something went wrong",
                  "Unable to delete release",
                  this.$toastPosition
                );
              }
            });
        },
      });
    },
    updateRelease(status) {
      this.$vs.loading();
      this.btnLoading = true;
      const uuid = this.$route.params.id;
      let fetch = {
        path: `admin/incoming/${uuid}/update`,
        data: {
          status,
          ...(status == "rejected" && {
            reason:
              this.reasonSelected == "Others"
                ? this.reason
                : this.reasonSelected,
          }),
        },
      };
      this.$store
        .dispatch("putRequest", fetch)
        .then(() => {
          this.$toast.success(
            "Release status updated successfully",
            "Release status updated",
            this.$toastPosition
          );
          this.reasonModal = false;
          this.$vs.loading.close();
          this.btnLoading = false;
          this.reasonSelected = "";
          this.reason = "";
          setTimeout(() => {
            location.reload();
          }, 1500);
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.btnLoading = false;
          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Release Status Update Failed",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Unable to update release status",
              "Release Status Update Failed",
              this.$toastPosition
            );
          }
        });
    },
    getReleaseByID() {
      console.log("***Eureka***")
      this.loading = true;
      const uuid = this.$route.params.id;
      let fetch = {
        path: `/admin/releases/${uuid}`,
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.releaseData = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Release Info",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "Release Info",
              text: "Unable to get Release",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
    downloadAudioFile(source) {
      let url = source;
      window.open(url, "_blank");
    },
    viewSplitListing(item) {
      this.splitView = true;
      this.splitData = item;
    },
    playAudio(source, id) {
      this.$store.commit("audioPlay", {
        status: true,
        source,
        id,
      });
    },
  },
};
</script>
<style lang="scss">
.albumImg {
  border-radius: 10px;
}

.releaseData {
  .type {
    color: black;
    text-transform: uppercase;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }

  .title {
    font-size: 5em;
    line-height: 1;
    font-weight: bold;
    color: black;
  }
}

.releaseinf {
  font-size: 14px;
  margin-bottom: 0px;

  .titl {
    color: black;
    letter-spacing: 1px;
    font-weight: bold;
  }
}

.metaDisplay {
  .headi {
    text-transform: uppercase;
    font-size: 12px;
    color: black;
    letter-spacing: 1px;
    border-radius: 26px;
    margin: 0;
  }

  .data {
    color: black;
  }
}

.download-art {
  position: relative;

  .vs-icon {
    cursor: pointer;
    background: #71b682;
    color: white;
    font-size: 17px;
    padding: 7px;
    border-radius: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    border: 3px solid white;
  }
}
</style>
